'use client';

import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { TopNavigationQueryResponseTypes } from '@src/graphql/queries.types';
import useGsapLoaded from '@src/hooks/useGsapLoaded';
import scrollToSection from '@src/utils/scrollToSection';
import Container from '../Container';
import NextImage from '../NextImage';
import Announcement from '../AnnouncementBar/AnnouncementBar';
export const NavListRender = dynamic(() => import('./NavListRender'));
export default function TopNavigation({
  data
}: {
  data: TopNavigationQueryResponseTypes;
}) {
  const isGsapLoaded = useGsapLoaded(['ScrollTrigger', 'ScrollToPlugin']);
  useEffect(() => {
    if (isGsapLoaded) {
      const navElement = document.querySelector('[data-animation="top-navigation"]');
      const hero = document.querySelector('[data-animation="home-page-hero"]');
      if (navElement && hero) {
        window.ScrollTrigger.matchMedia({
          // Only applies for screens under 1024px
          '(max-width: 1023px)': function () {
            window.gsap.set(navElement, {
              opacity: 0.95,
              pointerEvents: 'auto',
              position: 'fixed',
              top: 0
            });
          },
          // Default behavior for screens 1024px and above
          '(min-width: 1024px)': function () {
            window.gsap.set(navElement, {
              opacity: 0,
              pointerEvents: 'none'
            });
            window.gsap.to(navElement, {
              opacity: 0.95,
              pointerEvents: 'auto',
              ease: 'power4.out',
              scrollTrigger: {
                trigger: hero,
                start: '30%',
                end: '50%',
                scrub: true,
                toggleActions: 'play none none reverse'
              }
            });
          }
        });

        // Force a refresh of ScrollTrigger to ensure triggers are calculated correctly
        window.ScrollTrigger.refresh();
      }
    }
  }, [isGsapLoaded]);
  return <>
      <nav data-animation='top-navigation' className='fixed z-[101] flex h-[6.5rem] w-[100%] items-center justify-center bg-background-secondary opacity-0 transition-opacity duration-300 ease-in-out'>
        <Container HtmlTag='div' className='top-0  z-[101] !mb-[0] flex h-[100%] w-[100%] items-center md:!mb-[0] lg:!mb-[0] 4xl:!px-[0]' data-sentry-element="Container" data-sentry-source-file="TopNavigationUI.tsx">
          <a href='/' className='relative z-[10000] mr-auto flex h-[7.3rem] items-center'>
            <NextImage className='relative top-[-0.15rem] w-[16rem] sm:w-[17.5rem] ' src={data?.topNavigation?.logo?.url} alt={data?.topNavigation?.logo?.description} aspectRatio={data?.topNavigation?.logo?.width / data?.topNavigation?.logo?.height} width={data?.topNavigation?.logo?.width} data-sentry-element="NextImage" data-sentry-source-file="TopNavigationUI.tsx" />
          </a>

          <ul className='flex items-center'>
            {data?.topNavigation?.navItemsCollection?.items.slice(0, 1).map(link => <li key={link?.sys?.id} onClick={scrollToSection({
            id: 'faq',
            isGsapLoaded
          })} className='cursor-pointer'>
                <button className='!cursor-pointer rounded-lg px-[1.5rem] py-[1rem] font-[400] text-body-copy xl:px-[2rem]'>
                  FAQ
                </button>
              </li>)}
            <li onClick={scrollToSection({
            id: 'download-movehealth',
            isGsapLoaded
          })} className='cursor-pointer'>
              <button className='bg-transparent common-btn-transition relative bottom-[0.1rem] flex h-[4.4rem] items-center justify-center rounded-[0.6rem] border-[0.1rem] border-primary !px-[1.6rem] !py-[0.8rem] px-[2rem] text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] text-primary transition-colors duration-300 ease-in-out hover:border-primary--v2 hover:text-primary--v2'>
                <span className='md:hidden'>Download</span>
                <span className='hidden md:inline'>Download for free</span>
              </button>
            </li>
          </ul>
        </Container>
      </nav>

      <Announcement data-sentry-element="Announcement" data-sentry-source-file="TopNavigationUI.tsx" />
    </>;
}