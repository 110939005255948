'use client';

import React from 'react';
import Script from 'next/script';
import { ThirdPartyScriptsPropTypes } from './ThirdPartyScripts.types';
declare global {
  interface Window {
    gsap?: any;
    ScrollTrigger?: any;
    ScrollToPlugin?: any;
  }
}
const ThirdPartyScripts: React.FC<ThirdPartyScriptsPropTypes> = () => {
  // Function to register ScrollTrigger plugin
  const handleLoadScrollTrigger = () => {
    if (window.gsap && window.ScrollTrigger) {
      window.gsap.registerPlugin(window.ScrollTrigger);
    }
    if (window.gsap && window.ScrollToPlugin) {
      window.gsap.registerPlugin(window.ScrollToPlugin);
    }
  };
  return <>
      <meta name='google-site-verification' content='Rm1rkDn1WoXyv1WaLZ2PZDt6O_nANcy3mO54aQKx4r0' data-sentry-element="meta" data-sentry-source-file="ThirdPartyScripts.tsx" />
      {/* Load Google Analytics and Google Tag Manager scripts */}
      <noscript>
        <iframe src='https://www.googletagmanager.com/ns.html?id=GTM-TWJKLVF5' height='0' width='0' className='invisible hidden' />
      </noscript>

      <Script id='google-analytics' strategy='lazyOnload' data-sentry-element="Script" data-sentry-source-file="ThirdPartyScripts.tsx">
        {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'GTM-TWJKLVF5');
            `}
      </Script>
      <Script id='google-tag-manager' strategy='lazyOnload' data-sentry-element="Script" data-sentry-source-file="ThirdPartyScripts.tsx">
        {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-TWJKLVF5');
            `}
      </Script>
      {/* GSAP Scripts */}
      <Script src='https://cdn.jsdelivr.net/npm/gsap@3/dist/gsap.min.js' strategy='afterInteractive' data-sentry-element="Script" data-sentry-source-file="ThirdPartyScripts.tsx" />
      <Script src='https://cdn.jsdelivr.net/npm/gsap@3.12.5/dist/ScrollTrigger.min.js' strategy='afterInteractive' onLoad={handleLoadScrollTrigger} data-sentry-element="Script" data-sentry-source-file="ThirdPartyScripts.tsx" />
      <Script src='https://cdn.jsdelivr.net/npm/gsap@3.12.5/dist/ScrollToPlugin.min.js' strategy='afterInteractive' onLoad={handleLoadScrollTrigger} data-sentry-element="Script" data-sentry-source-file="ThirdPartyScripts.tsx" />
    </>;
};
export default ThirdPartyScripts;