import React from 'react';
import { AnnouncementPropTypes } from './AnnouncementBar.types';
const Announcement: React.FC<AnnouncementPropTypes> = ({}) => {
  return <div className='fixed top-[6.5rem] z-[1000000] flex h-[auto] w-[100%] items-center justify-center bg-[#294054] py-[1rem] opacity-[0.95] lg:static lg:top-[0] lg:opacity-[1]' data-sentry-component="Announcement" data-sentry-source-file="AnnouncementBar.tsx">
      <p className='max-w-[40rem] px-[1rem] text-center text-body-copy--light md:max-w-[50rem] lg:max-w-[unset]'>
        <span className='font-[500] text-body-copy--light'>TeleHab</span> is now{' '}
        <span className='font-[500] text-primary'> MoveHealth: </span>{' '}
        <span className='md:inline-block'>the home of your movement health journey.</span>{' '}
        <a href='https://valdhealth.com/news/introducing-movehealth' target='_blank' className='font-[300] text-body-copy--light underline'>
          Learn more.
        </a>
      </p>
    </div>;
};
export default Announcement;