const scrollToSection = ({ id, isGsapLoaded }: { id: string; isGsapLoaded: boolean }) => {
  return () => {
    if (!isGsapLoaded) return;

    const section = document.querySelector(`[data-id="${id}"]`);

    if (section) {
      const offsetY = section.getBoundingClientRect().top + window.scrollY - 60;

      setTimeout(() => {
        window.gsap.to(window, {
          duration: 2.5,
          scrollTo: {
            y: offsetY,
            autoKill: false,
          },
          ease: 'sine.inOut',
        });
      }, 100);
    }
  };
};

export default scrollToSection;
