import { useState, useEffect } from 'react';
import imagesLoaded from 'imagesloaded';

type DivRefTypes = 'ScrollTrigger' | 'ScrollToPlugin';

const useGsapLoaded = (plugins: DivRefTypes[] = []) => {
  const [isGsapLoaded, setIsGsapLoaded] = useState(false);

  useEffect(() => {
    const checkGsapPlugins = () => {
      // Check if GSAP and all plugins are loaded in the window object
      const arePluginsLoaded = plugins.every((plugin) => window[plugin as keyof Window]);
      return window.gsap && arePluginsLoaded;
    };

    const checkImagesLoaded = () => {
      // Ensure all images on the page are fully loaded
      const allImages = document.querySelectorAll('img');
      return new Promise<void>((resolve) => {
        imagesLoaded(allImages, { background: true }, () => {
          resolve();
        });
      });
    };

    const intervalId = setInterval(async () => {
      if (checkGsapPlugins()) {
        clearInterval(intervalId); // Clear the interval correctly
        await checkImagesLoaded();
        setIsGsapLoaded(true); // Set GSAP and images loaded state to true
      }
    }, 100);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId); // Clear the correct interval on unmount
  }, [plugins]);

  return isGsapLoaded;
};

export default useGsapLoaded;
