'use client';

import React from 'react';
import dynamic from 'next/dynamic';
import { NavListRenderPropTypes } from './TopNavigation.types';
import Container from '../Container';
import Button from '../Button';
import animation from './animate.module.css';
export const NavItemRender = dynamic(() => import('./NavItemRender'));
const NavListRender: React.FC<NavListRenderPropTypes> = ({
  list
}) => {
  const [isHamburgerOpen, setIsHamburgerOpen] = React.useState(true);
  const menuRef = React.useRef(null);
  const megaMenuFirstClasses = `before:bg-background-secondary before:content-[''] before:absolute before:left-[-1px] before:h-[100vh] before:w-[100vw] before:mt-[-3rem] xl:before:!hidden`;
  return <>
      <div ref={menuRef} className={`col-span-3 h-[88vh] overflow-y-auto overflow-x-hidden bg-background-secondary xl:hidden ${megaMenuFirstClasses} ${isHamburgerOpen ? `${animation.fadeIn}` : `${animation.fadeOut}`}`}>
        <Container HtmlTag='ul' className={`h-[auto] items-center !p-[0] pt-[3rem] xl:flex xl:h-[100%] xl:[&>li:not(:last-child)]:pr-[1.2rem]`} data-sentry-element="Container" data-sentry-source-file="NavListRender.tsx">
          {list.slice(0, 1).map(link => <NavItemRender key={link?.sys?.id} link={link} onToggleMobileNav={() => setIsHamburgerOpen(false)} />)}
        </Container>
        <ul className={`h-[auto] items-center gap-[2.4rem] xl:flex xl:h-[100%]`}>
          {list.slice(1, list?.length).map(link => <NavItemRender key={link?.sys?.id} link={link} onToggleMobileNav={() => setIsHamburgerOpen(false)} />)}
        </ul>
      </div>

      <ul className='!top-0 !right-[0] col-span-2 flex items-center gap-[3rem]'>
        {list.slice(0, 2).map(link => <NavItemRender key={link?.sys?.id} link={link} />)}
        <li>
          <Button btnClassName='relative bottom-[0.1rem]' secondary btnText='Download' href='/contact' align='justify-start' data-sentry-element="Button" data-sentry-source-file="NavListRender.tsx" />
        </li>
      </ul>
    </>;
};
export default NavListRender;